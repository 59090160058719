import { render, staticRenderFns } from "./plant-info.vue?vue&type=template&id=47a36ddc&scoped=true&"
import script from "./plant-info.vue?vue&type=script&lang=js&"
export * from "./plant-info.vue?vue&type=script&lang=js&"
import style0 from "./plant-info.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./plant-info.vue?vue&type=style&index=1&id=47a36ddc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a36ddc",
  null
  
)

export default component.exports